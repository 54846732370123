import Fade from "react-reveal/Fade"
import React from "react"

import Button from "../../components/Button"
import ChristmasImg from "../../common/assets/image/elegant/christmas/12dayss.jpg"
import Container from "../../components/UI/Container"
import Text from "../../components/Text"
import SectionWrapper, { ButtonGroup, ContentSection } from "./christmas.style"

const BlackFriday = () => {
  return (
    <SectionWrapper>
      <Container width="1340px">
        <Fade up delay={200}>
          <ContentSection>
            <div className="content">
              <Text content="So, with Christmas just around the corner we wanted to do something a little different this year to help you either spoil yourself or a loved one with our 12 days of Christmas Advent Calendar of promotions." />
              <Text content="Each offer is valid for 48 hours only. Once the time is up the offer is gone. Our Advent Calendar of offers starts on the 1st December and there is a limited amount available of each offer!" />
              <Text
                content="Below is the 6 different vouchers we are offering from the 1st December to the 12th December."
                className="terms"
              />
              <img src={ChristmasImg} alt="Christmas" />
              <Text
                content="So set your alarms not to miss out..."
                className="terms"
              />
              <Text
                content="The link to buy each voucher is here!"
                className="terms"
              />

              <iframe
                src="https://www.robovoucher.com/buy/list/UKDBR9/"
                name="RoboVoucherFrame"
                id="RoboVoucherFrame"
                scrolling="auto"
                frameborder="0"
                marginheight="0px"
                marginwidth="0px"
                height="750px"
                width="100%"
              />

              <Text
                content="We hope you have a lovely festive season and we cannot wait to treat you to get you party ready this December."
                className="terms"
              />
              <Text
                content="Love"
                className="terms"
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#297E7F",
                }}
              />
              <Text
                content="The Elegant Beauty Clinic Team"
                className="terms"
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#297E7F",
                }}
              />

              <Fade up delay={200}></Fade>
            </div>
          </ContentSection>
        </Fade>
      </Container>
    </SectionWrapper>
  )
}

export default BlackFriday
